/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        function random(owlSelector){
          owlSelector.children().sort(function(){
              return Math.round(Math.random()) - 0.5;
          }).each(function(){
            $(this).appendTo(owlSelector);
          });
        }

        $('.owl-carousel').each(function() {
          var $owl = $(this);

          $owl.on('initialize.owl.carousel', function(event){
              random($owl);
          });

          $owl.owlCarousel({
            items: 1,
            nav: false,
            navigation: false,
            loop: false, 
            autoplay: false,
            autoWidth: false,
            autoplayHoverPause: false,
            responsiveClass: true,
            singleItem: true,
            pagination: false,
            touchDrag: false,
            mouseDrag: false
          });
          
        });

        $('.open-password-modal').on('click', function(e) {
          e.preventDefault();
          $('#modal-password').modal('show');
        });

        var $modalPasswordForm = $('#modal-password-form');

        $modalPasswordForm.find('.form-control').on('focus', function() {
          $modalPasswordForm.find('.alert-danger').hide();
          $modalPasswordForm.find('.has-error').removeClass('has-error');
        });

        $modalPasswordForm.submit(function(e) {
          e.preventDefault();

          var $form = $(this),
              formData = new FormData($form[0]);

          $.ajax({
            method: 'POST',
            url: $(this).attr('action'),
            data: formData,
            cache: false,
            contentType: false,
            processData: false
          }).success(function(response) {
            if (response.status === 'ERROR') {
              $form.find('.alert-danger').text(response.msg).show();
              $form.find('.form-group').addClass('has-error');
            } else {
              window.location.href = response.redirect;
            }

          });
        });

        if ($('.post-password-form').length && $('body').hasClass('page-child')) {
          $('#modal-password').modal('show');
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        
        function random(owlSelector){
          owlSelector.children().sort(function(){
              return Math.round(Math.random()) - 0.5;
          }).each(function(){
            $(this).appendTo(owlSelector);
          });
        }

        $(".owl-carousel").owlCarousel({
          navigation: true,
          navigationText: [
            "<i class='icon-chevron-left icon-white'></i>",
            "<i class='icon-chevron-right icon-white'></i>"
            ],
          beforeInit : function(elem){
            //Parameter elem pointing to $("#owl-demo")
            random(elem);
          }

        });
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
